import request from '@/utils/request'

// 获取用户名单(黑, 白, 中间)
export function getUserList (data) {
  return request({
    url: '/user/getUserList',
    data
  })
}

// 更新用户名单(黑, 白, 中间)
export function updateUserStatus (data) {
  return request({
    url: '/user/updateUserStatus',
    data
  })
}

// 获取所有app版本
export function getAllVersionInfo (data) {
  return request({
    url: '/user/getAllVersionInfo',
    data
  })
}

// 获取配置内容
export function getAuditConfigInfo (data) {
  return request({
    url: '/user/getAuditConfigInfo',
    data
  })
}

// 获取配置内容
export function saveAuditConfigInfo (data) {
  console.log(data)
  return request({
    url: '/user/saveAuditConfigInfo',
    data
  })
}

// 获取审核配置
export function getAuditConfig (data) {
  return request({
    url: '/user/getAuditConfig',
    data
  })
}

// 获取审核配置
export function saveAuditConfig (data) {
  return request({
    url: '/user/saveAuditConfig',
    data
  })
}
