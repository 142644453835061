<template>
  <section>
    <section class="block">
      <div class="name">
        审核状态
      </div>
      <div class="content">
        <template>
          <el-radio
            v-model="formData.auditStatus"
            :label="1"
          >
            开启
          </el-radio>
          <div class="description">
            期间符合条件的用户被标为黑名单
          </div>
          <section class="sub-block">
            <div class="rows">
              <div class="description">
                审核期间被标记为黑名单的用户
              </div>
              <el-radio
                v-model="formData.auditConf.otherStatus"
                :label="1"
              >
                一般模式，所有登录的新用户及中间态的用户
              </el-radio>
              <br>
              <el-radio
                v-model="formData.auditConf.otherStatus"
                :label="2"
              >
                严格模式，所有用户（包括白名单用户，新用户及中间态的老用户）
              </el-radio>
            </div>

            <div class="rows">
              <div class="description">
                例外：来自白名单IP的新用户或中间态用户审核期间不被标记成黑名单
                <div class="sub-description">
                  开启后，审核状态开启情况下会直接标记为白名单
                </div>
              </div>
              <el-radio
                v-model="formData.auditConf.iPListStatus"
                :label="1"
              >
                开启
              </el-radio>
              <br>
              <el-radio
                v-model="formData.auditConf.iPListStatus"
                :label="2"
              >
                关闭
              </el-radio>
            </div>

            <div class="rows">
              <div class="description">
                例外：来自白名单国家的新用户或中间态用户审核期间不被标记成黑名单
                <div class="sub-description">
                  开启后，审核状态开启情况下会直接标记为白名单
                </div>
              </div>
              <el-radio
                v-model="formData.auditConf.nationListStatus"
                :label="1"
              >
                开启
              </el-radio>
              <br>
              <el-radio
                v-model="formData.auditConf.nationListStatus"
                :label="2"
              >
                关闭
              </el-radio>
            </div>
          </section>
        </template>

        <template>
          <el-radio
            v-model="formData.auditStatus"
            :label="2"
          >
            关闭
          </el-radio>
          <div class="description">
            期间仅符合黑名单IP和黑名单国家用户登录的新用户及中间态的用户被标为黑名单
          </div>
        </template>
      </div>
    </section>
    <section class="block">
      <div class="name">
        黑名单设置
      </div>
      <div class="content">
        <div class="rows">
          <div class="description">
            来自黑名单IP的所有用户标记成黑名单
            <div class="sub-description">
              不受审核状态开关的影响
            </div>
          </div>
          <el-radio
            v-model="formData.blackListConf.iPListStatus"
            :label="1"
          >
            开启
          </el-radio>
          <br>
          <el-radio
            v-model="formData.blackListConf.iPListStatus"
            :label="2"
          >
            关闭
          </el-radio>
        </div>

        <div class="rows">
          <div class="description">
            来自黑名单国家的新用户或中间态用户标记成黑名单
            <div class="sub-description">
              不受审核状态开关的影响
            </div>
          </div>
          <el-radio
            v-model="formData.blackListConf.nationListStatus"
            :label="1"
          >
            开启
          </el-radio>
          <br>
          <el-radio
            v-model="formData.blackListConf.nationListStatus"
            :label="2"
          >
            关闭
          </el-radio>
        </div>

        <div class="rows">
          <div class="description">
            来自黑名单版本客户端的新用户或中间态用户标记成黑名单
            <div class="sub-description">
              不受审核状态开关的影响
            </div>
          </div>
          <el-radio
            v-model="formData.blackListConf.otherStatus"
            :label="1"
          >
            开启
          </el-radio>
          <br>
          <el-radio
            v-model="formData.blackListConf.otherStatus"
            :label="2"
          >
            关闭
          </el-radio>
        </div>
      </div>
    </section>

    <section class="save-or-reset">
      <el-button
        :type="isChanged ? 'primary' : 'info'"
        :disabled="!isChanged"
        :loading="updateLoading"
        @click="permissionDialogVisible = true"
      >
        保存更改
      </el-button>
      <el-button
        v-if="isChanged"
        @click="handleReset"
      >
        舍弃更改
      </el-button>
    </section>

    <el-dialog
      title="本操作需要验证权限"
      :visible.sync="permissionDialogVisible"
      width="30%"
      @close="password = ''"
    >
      <el-input
        v-model="password"
        placeholder="请输入密码"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="permissionDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleCheckPermission"
        >确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  getAuditConfig,
  saveAuditConfig
} from '@/api/netboom_ios.js'

import { mapState } from 'vuex'
import { isEqual, cloneDeep } from 'lodash'

const passwordTarget = process.env.VUE_APP_ENVIRONMENT === 'prod' ? '*P!LPruchaZiphl' : 'admin000000'
export default {
  data () {
    return {
      formData: {
        appId: 0,
        channel: 0,
        pkgType: 0,
        auditConf: {},
        blackListConf: {}
      },
      formDataOriginal: {},
      updateLoading: false,
      permissionDialogVisible: false,
      passwordTarget,
      password: ''
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel', 'pkgType']),
    isChanged () {
      return !isEqual(this.formDataOriginal, this.formData)
    }
  },
  created () {
    this.getAuditConfig()
  },
  methods: {
    checkPermission () {
      return this.password === this.passwordTarget
    },
    handleCheckPermission () {
      if (!this.checkPermission()) {
        this.$message.warning('密码不正确, 无权限操作')
      } else {
        this.formData.appId = this.appId
        this.formData.channel = this.channel
        this.formData.pkgType = this.pkgType
        this.handleUpdate()
        this.password = ''
        this.permissionDialogVisible = false
      }
    },
    getAuditConfig () {
      getAuditConfig({
        appId: this.appId,
        channel: this.channel,
        pkgType: this.pkgType
      }).then(res => {
        if (res.code === 200) {
          const temp = res.data
          // 避免bug
          temp.auditConf = temp.auditConf || {
            nationListStatus: 2,
            iPListStatus: 1,
            otherStatus: 2
          }
          temp.blackListConf = temp.blackListConf || {
            nationListStatus: 1,
            iPListStatus: 1,
            otherStatus: 1
          }

          this.formData = Object.assign(
            {
              appId: this.appId,
              channel: this.channel,
              pkgType: this.pkgType,
              auditStatus: 1,
              auditConf: {
                nationListStatus: 2,
                iPListStatus: 1,
                otherStatus: 2
              },
              blackListConf: {
                nationListStatus: 1,
                iPListStatus: 1,
                otherStatus: 1
              }
            },
            temp
          )
          this.formDataOriginal = cloneDeep(this.formData)
        }
      })
    },
    handleReset () {
      this.formData = cloneDeep(this.formDataOriginal)
    },
    handleUpdate () {
      this.updateLoading = true
      saveAuditConfig(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.formDataOriginal = cloneDeep(this.formData)
          }
        })
        .finally(() => {
          this.updateLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
  .block {
    display: flex;
    padding: 30px;
    border-bottom: 1px solid;
    >.name {
      width: 120px;
    }
    .description {
      font-size: 14px;
      margin: 6px 0 12px;
      .sub-description {
        color: #999;
        margin-top: 6px;
      }
    }
    .sub-block {
      padding: 20px 20px 20px 40px;
    }
    .rows {
      margin-bottom: 20px;
      .el-radio {
        margin-bottom: 4px;
      }
    }
  }

  .save-or-reset {
    padding: 30px 0 60px;
    display: flex;
    justify-content: center;
  }
</style>
