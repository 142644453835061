<template>
  <Layout>
    <el-tabs v-model="activeName">
      <!-- eslint-disable vue/max-attributes-per-line -->
      <el-tab-pane label="审核开关" name="examineSwitch" />
    </el-tabs>
    <examineSwitch />
  </Layout>
</template>

<script>
import examineSwitch from './component/ExamineSwitch.vue'
export default {
  components: { examineSwitch },
  data () {
    return {
      activeName: 'examineSwitch'
    }
  }
}
</script>

<style>

</style>
